<!--
 * @Description: 房企案例
 * @Author: wangying
 * @Date: 2021-10-08 15:08:19
-->
<template>
  <div>
    <div class="title">
      <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
    </div>
    <p class="hr"></p>
    <div>
      <div class="ofw" v-loading="loading">
        <vue-scroll :ops="ops">
          <div v-for="(item, index) in list" :key="index">

            <div class="fx htd" @click="findS(item.articleId, index)">
              <div class="date ">
                <p class="ys">{{ dateFormat(item.createdat, 'MM-dd') }}</p>
                <p class="ys1">{{ dateFormat(item.createdat, 'yyyy') }}</p>
                <p class="brder"></p>
              </div>
              <div class="row">{{ item.articleName.length > 30 ? item.articleName.slice(0, 28) + '...' : item.articleName
              }}
              </div>
              <div v-show="!item.flag" class="adds">+</div>
              <div v-show="item.flag" class="adds">-</div>
            </div>
            <div v-if="item.flag">
              <div class="main flex">
                <div class="file" v-for="(val, ins) in item.nodeVoList" :key="ins">
                  <span>附件{{ ins + 1 }}：</span>
                  <span @click="dldFile(val.noteId)" class="filecolor pointer">{{ val.nodeName }}</span>
                </div>
              </div>
              <div style="line-height: 2" v-html="item.content">
              </div>
            </div>
          </div>
        </vue-scroll>
        <!-- <div v-show="flag">
          白名单HTML
        </div> -->
      </div>
    </div>
    <div class="fx jct">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="10"
        prev-text="Prev" next-text="Next" layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import vuescrolljs from '@/mixin/index.js'
import ListTitle from "@/components/reuse/listTitle";
import "@/css/elememtUi.less";
import { articleListByPage, articleDetail } from '@/api'
import dateFormat from '@/utils/time'
import '@/css/content.less'

export default {
  mixins: [vuescrolljs],
  data() {
    return {
      loading: false,
      dateFormat,
      headerNmaeCh: '<p style="color: #57802b;">Case of real estate enterprises</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 35px;">房企案例</p>',
      list: [],
      pages: {
        page: 0,
        limit: 10
      },
      total: null,
      flag: false,
      // currentPage1: 1,
    };
  },
  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
    this.getApiList()
  },
  methods: {
    getApiList() {
      this.loading = true
      const params1 = {
        ...this.pages,
        catCode: "000044",
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleListByPage(params1).then((res) => {
        this.list = res.data.list
        this.total = res.data.total
        this.list.forEach(item => {
          item.flag = false
        });
        this.loading = false
      })
    },
    dldFile(id) {
      window.location.href =
        `http://gsc.see.org.cn/file/file/api/download?route=file&nodeId=` + id;
    },
    findS(id, index) {

      this.list = this.list.map((item, i) => {
        if (index == i) {
          item.flag = !item.flag
          if (item.flag) {
            const params1 = {
              articleId: id,
            };
            this.loading = true;
            articleDetail(params1).then((res) => {
              this.loading = false;

              item.nodeVoList = res.data.nodeVoList || [];
              // this.list = [res.data]
              // this.content =
            });
          }
        }
        return item
      })
      //   this.list = [1]
      //   this.flag = true
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.page = val
      this.getApiList()
    }
  },
  components: {
    ListTitle
  }
};
</script>
<style lang="less" scoped>
.title {
  margin-top: 30px;
}

.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #8c8887;
}

.fx {
  display: flex;
}

.jct {
  padding-top: 20px;
  height: 60px;
  justify-content: right;
  margin-right: 30px;
}

.htd {
  height: 60px;
  width: 98%;
  border-bottom: 1px solid #b4c56a;
}

.date {
  width: 80px;
  position: relative;

  .ys {
    margin-top: 6px;
    color: #57802b;
    font-size: 20px;
    font-weight: 700;
  }

  .ys1 {
    color: #cfd0d0;
    font-size: 14px;
  }

  .brder {
    height: 40px;
    position: absolute;
    top: 10px;
    right: 0;
    border-right: 1px solid #b4c56a;
  }
}

.row {
  flex: 1;
  padding-left: 35px;
  line-height: 60px;
  font-size: 20px;
  color: #8c8887;
}

.adds {
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 1px solid #b4c56a;
  border-radius: 50%;
  color: #b4c56a;
  position: relative;
  top: 20px;
  left: -8px;
}

.ofw {
  height: 620px;
  overflow: auto;
}

.main {
  min-height: 20px;

  color: #5e5d5d;

  .file {
    margin: 10px;
    width: 20%;
  }

  .filecolor {
    color: #337ab7;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
